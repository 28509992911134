<template>
  <q-card flat bordered style="width:300px">
    <q-card-section class="q-gutter-md">
      <q-list>
        <q-item>
          <q-item-section class="text-grey">Nama</q-item-section>
          <q-item-section>{{ selNilai.nama }}</q-item-section>
        </q-item>
        <q-item>
          <q-item-section class="text-grey">Mapel</q-item-section>
          <q-item-section
            >{{ selMapel.label }} ({{ selKategori }})</q-item-section
          >
        </q-item>
        <q-item>
          <q-item-section class="text-grey">Nilai Terakhir</q-item-section>
          <q-item-section>{{ bundleRemed.nilai_lama }}</q-item-section>
        </q-item>
        <q-item>
          <q-item-section class="text-grey">KKM</q-item-section>
          <q-item-section>{{ kkm }}</q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <q-separator></q-separator>
    <q-card-section
      class="q-gutter-md"
      v-if="selNilai.nilai < kkm && selNilai.nilai != null"
    >
      <q-input
        outlined
        stack-label
        type="number"
        label="Nilai Setelah Remedial"
        v-model="bundleRemed.nilai"
      ></q-input>
    </q-card-section>
    <q-card-actions class="q-pa-none" align="stretch">
      <q-btn
        class="full-width no-border-radius"
        v-if="selNilai.nilai < kkm && selNilai.nilai != null"
        :disable="bundleRemed.nilai == null"
        unelevated
        color="primary"
        label="SIMPAN"
        @click="rem = true"
      ></q-btn>
    </q-card-actions>
    <q-separator></q-separator>

    <q-markup-table
      flat
      wrap-cells
      dense
      separator="horizontal"
      class="stickyTable no-border-radius bg-grey-4"
      style="height:calc(100vh - 352px);"
    >
      <thead>
        <tr>
          <th>Tanggal</th>
          <th>Nilai Lama</th>
          <th>Nilai Baru</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(val, i) in listRemed" :key="i">
          <td>{{ val.tanggal | moment("DD MMM YYYY") }}</td>
          <td>{{ val.nilai_asal }}</td>
          <td>{{ val.nilai }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <q-separator></q-separator>
    <q-card-actions class="row justify-between">
      <q-btn flat color="primary" label="back" @click="$emit('back')"></q-btn>
    </q-card-actions>
    <q-dialog v-model="rem">
      <q-card>
        <q-card-section>
          <q-card-section>
            <p>
              <strong
                >Apa anda yakin ingin menyimpan nilai setelah perbaikan?</strong
              >
            </p>
          </q-card-section>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn outline color="primary" label="Batal" v-close-popup></q-btn>
          <q-btn
            color="primary"
            label="Input"
            @click="simpan"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
export default {
  props: ["selNilai", "selMapel", "selKategori"],
  data() {
    return {
      rem: false,
      kkm: 0,
      guard: false,
      nilai: 0,
      id_guru: 10,
      listRemed: [],
      bundleRemed: {
        nilai: null,
      },
    };
  },
  async mounted() {
    await this.getKKM();
    await this.prepareNilai();
    await this.getRemed();
  },
  methods: {
    getKKM() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(`penilaian/input_nilai/getkkm/${this.selMapel.value}`)
        .then((resp) => {
          this.kkm = parseFloat(resp.data.kkm);
        });
      this.$q.loading.hide();
    },
    async getRemed() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `penilaian/input_nilai/getremed/${this.bundleRemed.id_nilai}/${this.selKategori}`
        )
        .then((resp) => {
          this.listRemed = resp.data;
        });
      this.$q.loading.hide();
    },
    async simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .post(
          `penilaian/input_nilai/addremed/${this.selKategori}`,
          this.bundleRemed
        )
        .then((resp) => {
          this.$q.notify({
            message: "Nilai Remedial Berhasil disimpan",
            color: "positive",
          });
        });
      await this.$http
        .put(
          `penilaian/input_nilai/remednilai/${this.kkm}/${this.bundleRemed.id_nilai}/${this.selKategori}/${this.bundleRemed.nilai}/${this.bundleRemed.nilai_lama}`
        )
        .then((resp) => {});
      this.$q.loading.hide();
      this.$emit("back");
    },
    async prepareNilai() {
      this.bundleRemed.nilai_lama = this.selNilai.nilai;
      this.bundleRemed.nilai = 0;
      this.bundleRemed.id_mapel = this.selMapel.value;
      this.bundleRemed.id_nilai = this.selNilai.id;
    },
  },
};
</script>
