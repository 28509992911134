<template>
  <q-card flat bordered style="width:300px">
    <q-card-section class="row justify-between">
      <!-- <q-toggle v-model="is_editting" icon="edit" /> -->
      <div v-show="is_editting">
        <q-btn
          flat
          v-show="data.kategori == 'KETERAMPILAN'"
          icon="visibility"
          color="primary"
          @click="pengetahuan = true"
        ></q-btn>
        <q-btn
          unelevated
          color="primary"
          icon="add"
          @click="resetbundleKD(), (act = 'TAMBAH'), (is_adding = true)"
        ></q-btn>
      </div>
    </q-card-section>
    <q-list separator style="height: calc(100vh - 210px);">
      <q-item v-if="listKD.length == 0"
        >Belum ada Kompetensi Dasar di mata pelajaran
        {{ data.mapel.label }}</q-item
      >
      <q-item
        :clickable="!is_editting"
        v-ripple
        v-for="(el, idx) in listKD"
        :key="idx"
        @click="next(el)"
      >
        <q-item-section avatar>{{ el.nomor }}</q-item-section>
        <q-item-section>{{ el.nama }}</q-item-section>
        <q-item-section v-if="is_editting" side>
          <div class="row">
            <q-btn
              flat
              dense
              icon="edit"
              @click="getKDdetail(idx), (act = 'EDIT'), (is_adding = true)"
            ></q-btn>
            <q-btn
              flat
              dense
              color="negative"
              icon="delete"
              @click="(todel = el), (guard = true)"
            ></q-btn>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <q-card-actions v-if="!is_editting">
      <q-btn flat color="primary" label="back" @click="$emit('back')"></q-btn>
    </q-card-actions>

    <q-dialog persistent v-model="is_adding">
      <q-card>
        <q-card-section>
          <q-input
            outlined
            stack-label
            label="Mata Pelajaran"
            v-model="data.mapel.label"
            readonly
          ></q-input>
          <q-input
            outlined
            type="number"
            stack-label
            label="Nomor"
            v-model="bundleKD.nomor"
          ></q-input>
          <q-input
            outlined
            stack-label
            label="Nama"
            v-model="bundleKD.nama"
          ></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn outline color="primary" label="cancel" v-close-popup></q-btn>
          <q-btn
            color="primary"
            v-if="act == 'TAMBAH'"
            label="add"
            v-close-popup
            @click="addKD"
          ></q-btn>
          <q-btn
            color="primary"
            v-if="act == 'EDIT'"
            label="edit"
            v-close-popup
            @click="editKD"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog persistent v-model="guard">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="report_prblem" color="negative" text-color="white" />
          <span class="q-ml-sm">
            <strong class="q-ml-sm">
              Apa anda yakin ingin menghapus kompetensi dasar "{{
                todel.nama
              }}"?
            </strong>
          </span>
        </q-card-section>
        <q-card-section>
          <p>
            silahkan tulis "{{ todel.nama }}" tanpa tanda kutip untuk
            melanjutkan
          </p>
          <q-input outlined v-model="pass"></q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" @click="pass = ''" v-close-popup />
          <q-btn
            flat
            :disable="
              !(this.pass.toLowerCase() == this.todel.nama.toLowerCase())
            "
            label="Ya"
            @click="deleteKD(todel.id), (pass = '')"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="pengetahuan">
      <q-card>
        <q-markup-table class="stickyTable">
          <thead>
            <tr>
              <th>No.KD</th>
              <th>Nama KD</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(val, i) in kd_pengetahuan" :key="i">
              <td>{{ val.nomor }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-btn unelevated label="Close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      pengetahuan: false,
      kd_pengetahuan: [],

      pass: "",
      guard: false,
      todel: {
        nama: "",
      },

      act: "",

      id_guru: 10,
      listKD: [],
      is_editting: false,
      is_adding: false,
      bundleKD: {
        id_mapel: null,
        nomor: 1,
        nama: "",
      },
    };
  },
  computed: {},
  mounted() {
    this.getKD();
    if (this.data.kategori == "KETERAMPILAN") {
      this.getPengetahuan();
    }
  },
  methods: {
    getPengetahuan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(
          `/penilaian/kompetensi/getlistpengetahuan/${this.data.mapel.value}`
        )
        .then((resp) => {
          this.kd_pengetahuan = resp.data;
        });
      this.$q.loading.hide();
    },

    getKDdetail(idx) {
      this.bundleKD = JSON.parse(JSON.stringify(this.listKD[idx]));
    },
    resetbundleKD() {
      this.bundleKD = {
        id_mapel: null,
        nomor: 1,
        nama: "",
      };
    },
    next(el) {
      if (!this.is_editting) {
        this.$emit("next", el);
      }
    },
    async getKD() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .get(
          `/penilaian/kompetensi/getlistkd/${this.data.mapel.value}/${this.data.kategori}`
        )
        .then((resp) => {
          this.listKD = resp.data;
        });
      this.$q.loading.hide();
    },
    addKD() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.bundleKD.id_mapel = this.data.mapel.value;

      this.$http
        .post(
          `/penilaian/kompetensi/tambah/${this.data.mapel.value}/${this.data.kategori}`,
          this.bundleKD
        )
        .then((resp) => {
          this.$q.notify({
            message: "Kompetensi Dasar berhasil ditambahkan",
            color: "positive",
          });
        });
      this.$q.loading.hide();
      this.getKD();
    },
    editKD() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http
        .put(`/penilaian/kompetensi/edit`, this.bundleKD)
        .then((resp) => {
          this.$q.notify({
            message: "Kompetensi Dasar berhasil diupdate",
            color: "positive",
          });
        });
      this.$q.loading.hide();
      this.getKD();
    },
    async deleteKD(id) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .delete("/penilaian/kompetensi/hapus/" + id)
        .then((resp) => {});
      await this.$http
        .delete("/penilaian/kompetensi/hapusdetailkd/" + id)
        .then((resp) => {});
      this.$q.loading.hide();
      this.getKD();
    },
  },
};
</script>
