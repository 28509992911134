<template>
  <q-card
    flat
    bordered
    class="column justify-between"
    style="width:300px;height:calc(100vh - 85px)"
  >
    <q-card-section class="q-gutter-md">
      <q-select
        stack-label
        label="Pilih Mapel"
        outlined
        v-model="selected.mapel"
        :options="option.mapel"
        @input="getKelas"
      ></q-select>
      <q-select
        stack-label
        label="Pilih Kelas"
        outlined
        v-model="selected.kelas"
        :options="option.kelas"
        :disable="!option.kelas.length"
      ></q-select>
      <q-select
        stack-label
        label="Pilih Kategori"
        outlined
        v-model="selected.kategori"
        :options="option.kategori"
      ></q-select>
    </q-card-section>
    <q-card-actions>
      <q-btn
        :disable="selected.kelas == null || selected.kategori == null"
        unelevated
        class="full-width"
        color="primary"
        label="next"
        @click="$emit('next', selected)"
      ></q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      option: {
        kelas: [],
        mapel: [],
        kategori: [
          "TUGAS",
          "UH",
          "KETERAMPILAN",
          "UTS",
          "UAS",
          "RESPON",
          "ADAB",
        ],
      },
      selected: {
        kelas: null,
        mapel: null,
        kategori: null,
      },
    };
  },
  async mounted() {
    await this.getUser();
    await this.getMapel();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.selected.kelas = null;
      this.option.kelas = [];
      await this.$http
        .get(
          `/penilaian/input_nilai/getkelas/${this.selected.mapel.value}/${this.user.id}/${this.user.is_super_user}`
        )
        .then((resp) => {
          this.option.kelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getMapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/penilaian/input_nilai/getmapel/${this.user.id}/${this.user.is_super_user}`
        )
        .then((resp) => {
          this.option.mapel = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>
