<template>
  <q-card flat bordered style="width:300px">
    <q-card-section class="row justify-between">
      <q-toggle v-model="is_editting" icon="edit" />
      <div class="row q-gutter-sm">
        <q-btn
          unelevated
          v-show="is_editting"
          color="primary"
          icon="add"
          @click="tambahTugas"
        ></q-btn>
        <q-btn
          flat
          v-show="is_editting"
          color="negative"
          icon="delete"
          @click="guard = true"
        ></q-btn>
      </div>
    </q-card-section>
    <q-list separator style="height: calc(100vh - 210px);">
      <q-item v-if="listTugas.length == 0">
        Belum ada
        {{ selKategori.toLowerCase() }}
        di Kompetensi Dasar {{ selKD.nama }}
      </q-item>
      <q-item
        :clickable="!is_editting"
        v-for="(el, idx) in listTugas"
        :key="idx"
        @click="next(el)"
      >
        <q-item-section>{{ el.nama }}</q-item-section>
        <q-item-section side>
          <!-- <q-btn
            v-show="is_editting"
            flat
            dense
            color="negative"
            icon="delete"
            @click="todel=el,guard=true"
          ></q-btn>-->
        </q-item-section>
      </q-item>
    </q-list>
    <q-card-actions>
      <q-btn
        flat
        color="primary"
        v-show="!is_editting"
        label="back"
        @click="$emit('back')"
      ></q-btn>
    </q-card-actions>

    <q-dialog v-if="listTugas.length > 0" persistent v-model="guard">
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="report_prblem" color="negative" text-color="white" />
          <span class="q-ml-sm">
            <strong class="q-ml-sm">
              Apa anda yakin ingin menghapus "{{
                listTugas[listTugas.length - 1].nama
              }}" ?
            </strong>
          </span>
        </q-card-section>
        <q-card-section>
          <p>
            silahkan tulis "{{ listTugas[listTugas.length - 1].nama }}" tanpa
            tanda kutip untuk melanjutkan
          </p>
          <q-input outlined v-model="pass"></q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" @click="pass = ''" v-close-popup />
          <q-btn
            flat
            :disable="
              !(
                this.pass.toLowerCase() ==
                listTugas[listTugas.length - 1].nama.toLowerCase()
              )
            "
            label="Ya"
            @click="hapusTugas(), (pass = '')"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
export default {
  props: ["listSiswa", "selKD", "selKategori"],
  data() {
    return {
      id_guru: 10,
      listTugas: [],
      is_editting: false,
      guard: false,
      pass: "",
    };
  },
  mounted() {
    this.getTugas();
  },
  methods: {
    next(el) {
      if (!this.is_editting) {
        this.$emit("next", el);
      }
    },
    async getTugas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/penilaian/input_nilai/getlisttugas/${this.selKategori}/${this.selKD.id}/${this.listSiswa[0].id_nilai_akademik}`
        )
        .then((resp) => {
          this.listTugas = resp.data;
        });
      this.$q.loading.hide();
    },
    async tambahTugas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let name = `${this.selKategori} ${this.listTugas.length + 1}`;
      await this.$http
        .post(
          `/penilaian/input_nilai/tambah_tugas/${this.selKategori}/${this.selKD.id}/${name}`,
          this.listSiswa
        )
        .then((resp) => {
          this.getTugas();
        });
      this.$q.loading.hide();
    },
    async hapusTugas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .put(
          `/penilaian/input_nilai/hapus_tugas/${this.selKategori}/${this.selKD.id}`,
          this.listSiswa
        )
        .then((resp) => {
          this.$q.loading.hide();
          this.getTugas();
        });
    },
  },
};
</script>
