<template>
  <q-card flat bordered style="width:300px">
    <q-card-section class="row">
      <q-toggle class="col-4" v-model="is_editting" icon="edit" />
      <q-input
        dense
        class="col-8"
        v-model="searchTerm"
        outlined
        label="cari siswa"
      ></q-input>
    </q-card-section>
    <q-markup-table
      wrap-cells
      dense
      separator="horizontal"
      class="stickyTable no-border-radius bg-grey-4"
      style="height:calc(100vh - 211px);"
    >
      <thead>
        <tr>
          <th style="width:10%">NO</th>
          <th style="width:50%">NAMA</th>
          <th style="width:40%">
            NILAI
            <q-btn
              flat
              v-show="is_editting"
              class="q-ml-xs"
              color="white"
              dense
              size="sm"
              icon="add"
              @click="masal = true"
            ></q-btn>
          </th>
          <th v-show="!is_editting"></th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr
          v-for="(val, i) in filSiswa"
          :key="i"
          :class="{ 'bg-red-2': val.is_remed }"
        >
          <td>{{ i + 1 }}</td>
          <td>{{ val.nama }}</td>
          <template>
            <q-td>
              {{ formatNilai(val.nilai) }}
              <q-popup-edit
                :value="nilai"
                v-if="is_editting"
                title="Masukan Nilai"
              >
                <q-input type="number" v-model="val.nilai" dense autofocus />
              </q-popup-edit>
            </q-td>
          </template>
          <td v-show="!is_editting">
            <q-btn
              flat
              color="black"
              icon="construction"
              size="sm"
              dense
              @click="$emit('next', val)"
            ></q-btn>
          </td>
        </tr>
      </tbody>
    </q-markup-table>
    <q-card-actions class="row justify-between">
      <q-btn
        v-if="
          selKategori == 'TUGAS' ||
            selKategori == 'UH' ||
            selKategori == 'KETERAMPILAN'
        "
        flat
        color="primary"
        label="back"
        @click="$emit('back')"
      ></q-btn>
      <q-btn
        flat
        color="primary"
        v-else
        label="back"
        @click="$emit('back2')"
      ></q-btn>
      <q-btn
        color="primary"
        v-show="is_editting"
        label="simpan"
        @click="guard = true"
      ></q-btn>
    </q-card-actions>
    <q-dialog v-model="masal">
      <q-card>
        <q-card-section>
          <q-input
            label="Nilai Masal"
            v-model="nilai"
            type="number"
            stack-label
          ></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn outline color="primary" label="Batal" v-close-popup></q-btn>
          <q-btn
            color="primary"
            label="Input"
            @click="bulkInput"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="guard">
      <q-card>
        <q-card-section>
          <p>
            <strong>Apa anda yakin ingin menyimpan nilai?</strong>
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn outline color="primary" label="Batal" v-close-popup></q-btn>
          <q-btn
            color="primary"
            label="simpan"
            @click="simpan"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import jsPDF from "jspdf";
export default {
  props: ["listSiswa", "selKD", "selKategori", "tugas", "selMapel"],
  data() {
    return {
      is_editting: false,
      searchTerm: "",
      kkm: 0,
      guard: false,
      masal: false,
      selTugas: {
        nama: "no",
      },
      nilai: 0,
      id_guru: 10,
      listNilai: [],
    };
  },
  async mounted() {
    if (
      this.selKategori == "TUGAS" ||
      this.selKategori == "UH" ||
      this.selKategori == "KETERAMPILAN"
    ) {
      this.selTugas = this.tugas;
    }
    await this.getKKM();
    await this.prepareNilai();
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listNilai;
      else {
        return this.listNilai.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    formatNilai(nilai) {
      if (nilai == null) return "-";
      return nilai;
    },
    async getKKM() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(`penilaian/input_nilai/getkkm/${this.selMapel.value}`)
        .then((resp) => {
          this.kkm = parseFloat(resp.data.kkm);
        });
      this.$q.loading.hide();
    },
    async simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .put(
          `/penilaian/input_nilai/masukan/${this.selKategori}`,
          this.listNilai
        )
        .then((resp) => {
          this.$q.notify({
            message: "Nilai Berhasil Disimpan",
            color: "positive",
          });
        });
      this.$q.loading.hide();
      this.prepareNilai();
    },
    bulkInput() {
      for (let i in this.listNilai) {
        this.listNilai[i].nilai = this.nilai;
      }
    },
    async prepareNilai() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .post(
          `/penilaian/input_nilai/getnilaidetail/${this.selKategori}/${this.selTugas.nama}/${this.selKD.id}`,
          this.listSiswa
        )
        .then((resp) => {
          let carier = [];
          for (let i in resp.data) {
            let temp = {};
            temp.is_remed = false;
            if (parseFloat(resp.data[i].nilai) < this.kkm) {
              temp.is_remed = true;
            }
            temp.nama = this.listSiswa[i].nama;
            temp.id_siswa = this.listSiswa[i].id;
            temp.id = resp.data[i].id;
            if (resp.data[i].nilai == null) temp.nilai = null;
            else temp.nilai = parseFloat(resp.data[i].nilai);
            carier.push(temp);
          }

          this.listNilai = carier;
        });
      this.$q.loading.hide();
    },
  },
};
</script>
