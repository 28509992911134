<template>
  <div class="row justify-center bg-grey-3">
    <pilih-kelas v-if="step == 1" @next="showPilihKD"></pilih-kelas>
    <PilihKD
      v-if="step == 2"
      :data="step1Data"
      @back="step = 1"
      @next="showPilihTugas"
    ></PilihKD>
    <PilihTugas
      v-if="step == 3"
      :listSiswa="listSiswa"
      :selKD="step2Data"
      :selKategori="step1Data.kategori"
      @back="step = 2"
      @next="showInputNilai"
    ></PilihTugas>
    <InputNilai
      v-if="step == 4"
      :listSiswa="listSiswa"
      :selKategori="step1Data.kategori"
      :selMapel="step1Data.mapel"
      :tugas="step3Data"
      :selKD="step2Data"
      @back="step = 3"
      @back2="step = 1"
      @next="showRemed"
    ></InputNilai>
    <InputRemed
      v-if="step == 5"
      :selMapel="step1Data.mapel"
      :selNilai="step4Data"
      :selKategori="step1Data.kategori"
      @back="step = 4"
    ></InputRemed>
  </div>
</template>

<script>
import PilihKelas from "@/views/PenilaianInputNilaiMobile/PilihKelas";
import PilihKD from "@/views/PenilaianInputNilaiMobile/PilihKD";
import PilihTugas from "@/views/PenilaianInputNilaiMobile/PilihTugas";
import InputNilai from "@/views/PenilaianInputNilaiMobile/InputNilai";
import InputRemed from "@/views/PenilaianInputNilaiMobile/InputRemed";
export default {
  components: { PilihKelas, PilihKD, PilihTugas, InputNilai, InputRemed },
  data() {
    return {
      step: 1,
      step1Data: null,
      step2Data: null,
      step3Data: null,
      step4Data: null,
      listSiswa: [],
    };
  },
  mounted() {},
  methods: {
    showRemed(selectedNilai) {
      this.step4Data = selectedNilai;
      this.step = 5;
    },
    showInputNilai(selectedTugas) {
      this.step3Data = selectedTugas;
      this.step = 4;
    },
    async showPilihKD(selected) {
      this.step1Data = selected;
      await this.getListSiswa();

      if (this.listSiswa.length == 0) {
        this.$q.notify({
          message: "Tidak ada siswa di Kelas Terpilih",
          color: "negative",
        });
        this.step = 1;
      } else if (
        this.step1Data.kategori == "TUGAS" ||
        this.step1Data.kategori == "UH" ||
        this.step1Data.kategori == "KETERAMPILAN"
      ) {
        this.step = 2;
      } else {
        this.step2Data = { id: -1 };
        this.step = 4;
      }
    },
    async showPilihTugas(selectedKD) {
      this.step2Data = selectedKD;
      this.step = 3;
    },
    async getListSiswa() {
      await this.$http
        .get(`/penilaian/input_nilai/getsiswa/${this.step1Data.kelas.value}`)
        .then((resp) => {
          this.listSiswa = resp.data;
        });
      await this.checkNilaiAkademik();
      await this.getIDNilaiAkademik();
    },
    async checkNilaiAkademik() {
      // create nilai_akademik for siswa
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let bundle = {
        kelas: this.step1Data.kelas,
        mapel: this.step1Data.mapel,
        id_tahun_ajaran: localStorage.getItem("id_tahun_ajaran"),
        siswa: this.listSiswa,
      };
      await this.$http
        .post(`/penilaian/input_nilai/ceknilaiakademik`, bundle)
        .then((resp) => {});
      this.$q.loading.hide();
    },
    async getIDNilaiAkademik() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .post(
          `/penilaian/input_nilai/getid_nilai/${
            this.step1Data.kelas.id_guru_mapel
          }/${localStorage.getItem("id_tahun_ajaran")}`,
          this.listSiswa
        )
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.listSiswa));
          for (let i in resp.data) {
            cpy[i].id_nilai_akademik = resp.data[i];
          }
          this.listSiswa = cpy;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
